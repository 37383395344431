import React from "react";
import { Menu, MenuItem, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { makeStyles, withStyles } from "tss-react/mui";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useUserDispatch } from "../../../contexts/user/user.context";
import RoundedButton from "../../common/boxlty.button";
export const NavMenu: React.FC<{
  avatarAnchorEl: null | HTMLElement;
  setAvatarAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  avatarOpen: boolean;
  handleMenuClick: (e: any) => void;
}> = ({ avatarAnchorEl, avatarOpen, setAvatarAnchorEl, handleMenuClick }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const CustomMenuItem = withStyles(MenuItem, (theme) => ({
    root: {
      justifyContent: "flex-end",
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.medGray,
    },
  }));

  const handleClose = () => {
    setAvatarAnchorEl(null);
  };

  const openInNewTab = (url: string) => {
    const newTab = window.open(url, "_blank");
    newTab?.focus(); // Optional: Bring the new tab to focus
  };

  return (
    <>
      <Menu
        open={avatarOpen}
        onClose={handleClose}
        anchorEl={avatarAnchorEl}
        id="avatar-menu"
        MenuListProps={{
          "aria-labelledby": "avatar",
        }}
        className={classes.menuContainer}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <CustomMenuItem
          onClick={() => {
            navigate("/personalinfo");
          }}
        >
          Personal Information
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            navigate("/settings/account");
          }}
        >
          Account Settings
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            openInNewTab("https://instashowplus.com/privacy-policy/");
          }}
        >
          Privacy Policy
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            openInNewTab("https://instashowplus.com/terms/");
          }}
        >
          Terms
        </CustomMenuItem>
        <Divider />
        <CustomMenuItem
          onClick={() => {
            // try {
            //   dispatch({ type: "logout" });
            // } catch (error) {
            //   // console.log("error logging out ");
            //   return;
            // }
            // navigate("/login");
            setLogoutPopupOpen(true);
            handleClose();
          }}
        >
          Log Out
        </CustomMenuItem>
      </Menu>
      {logoutPopupOpen && (
        <div className={classes.ActionsPopupOverlay}>
          <div className={classes.PopupCentered}>
            {/* Popup content */}
            <p className={classes.PopupHeader}>Logout of InstaShow</p>
            <p className={classes.PopupText}>
              Are you sure you want to log out of InstaShow?
            </p>
            <div className={classes.PopupOptionsSection}>
              <RoundedButton
                text="NO"
                listener={() => {
                  setLogoutPopupOpen(false);
                }}
                boldText={true}
              />
              <RoundedButton
                text="YES"
                listener={() => {
                  try {
                    dispatch({ type: "logout" });
                  } catch (error) {
                    // console.log("error logging out ");
                    return;
                  }
                  navigate("/login");
                }}
                color="primary"
                boldText={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({ name: "avatar-menu" })((theme) => ({
  menuContainer: {
    position: "absolute",
    top: "12px",
    textAlign: "center",
    padding: theme.spacing(0.5),
    alignItems: "flex-end",
    display: "flex",
  },
  menuItem: {
    justifySelf: "right",
    display: "flex",
  },
  ActionsPopupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9998, // Adjust z-index as needed
  },
  PopupCentered: {
    top: "50%",
    left: "50%",
    //transform: "translate(-50%, -50%)",
    zIndex: 9999,
    minHeight: "200px",
    minWidth: "400px",
    backgroundColor: "white",
    borderRadius: "25px",
  },
  PopupHeader: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "21px",
    fontFamily: theme.fonts.secondary.bold,
  },
  PopupText: {
    textAlign: "center",
    fontSize: "14px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  PopupOptionsSection: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "center",
    marginBottom: "25px",
  },
  PopupButtonsSection: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "center",
    marginBottom: "25px",
  },
}));
