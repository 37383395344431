import React, { useState } from "react";
import { CircularProgress, SxProps, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as UploadIcon } from "../../styles/assets/logos/upload-icon.svg";
import { useUserState } from "../../contexts/user/user.context";
import { useApi } from "../../contexts/api/api.context";
import { mainTheme } from "../../../src/styles/mainTheme";

const useStyles = makeStyles({ name: "file-upload-component" })((theme) => ({
   wrapper: {
      cursor: "pointer",
   },
   root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: mainTheme.spacing(0.5),
      border: `${mainTheme.spacing(0.5)}px dashed ${
         mainTheme.palette.common.medGray
      }`,
      position: "relative",
      "& *": {
         color: mainTheme.palette.common.medGray,
      },
   },
   highlight: {
      borderColor: mainTheme.palette.primary.main,
   },
   hide: {
      width: 0,
      height: 0,
      opacity: 0,
   },
   click: {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
   },
   img: {
      width: "100%",
      height: "auto",
      position: "absolute",
      padding: mainTheme.spacing(1),
   },
}));
// must have unique id if using in multiples/array
interface IFileUpload {
   visible?: boolean;
   id?: string | number;
   multiple?: boolean;
   type?: string;
   height?: string;
   width?: string;
   headingText?: string;
   text?: string;
   sx?: SxProps;
   file: any;
   setFile: (event: any) => void;
   //   onUpload?: (media: Models.MediaContent, isUpload?: boolean) => void
}

export const FileUpload: React.FC<IFileUpload> = ({
   type,
   file,
   setFile,
   width,
   height,
   text,
   sx,
   id,
   multiple,
   headingText,
}) => {
   const { classes } = useStyles();
   const [highlight, setHighlight] = useState(false);
   const [loading, setLoading] = useState(false);
   const { user } = useUserState();
   //   const { upload, uploadPDF, uploadVideo } = useApi()

   const onDrop: React.DragEventHandler<HTMLDivElement> = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setHighlight(false);
      setFile(evt.dataTransfer.files.item(0));
   };

   const active: React.DragEventHandler<HTMLDivElement> = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setHighlight(true);
   };

   const inactive: React.DragEventHandler<HTMLDivElement> = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setHighlight(false);
   };

   const inputUpload: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      // console.log("file: ", evt.target.files?.item(0));
      setFile(evt.target.files?.item(0));
   };

   return (
      <div style={{ backgroundColor: "#EAEAEA", padding: "10px" }}>
         <div
            style={{
               margin: "5px",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: `${height}`,
               width: `${width}`,
            }}
         >
            <div className={classes.wrapper}>
               <div
                  onDragEnter={active}
                  onDragOver={active}
                  onDragLeave={inactive}
                  onDrop={onDrop}
                  className={`${classes.root} ${
                     highlight ? classes.highlight : ""
                  }`}
               >
                  {loading ? (
                     <CircularProgress color="primary" />
                  ) : (
                     <>
                        <div style={{ marginBottom: "10px" }}>
                           <UploadIcon />
                        </div>
                        <div
                           style={{
                              paddingLeft: "3px",
                              paddingRight: "3px",
                              paddingBottom: "1px",
                              paddingTop: "1px",
                              textDecoration: "underline",
                           }}
                        >
                           <Typography>
                              {headingText ? headingText : `Choose File`}
                           </Typography>
                        </div>

                        <Typography
                           style={{
                              fontFamily: mainTheme.fonts.primary.med,
                              color: mainTheme.palette.common.medGray,
                           }}
                           align="center"
                        >
                           {text ? text : `or Drag and Drop`}
                        </Typography>

                        {/* {file && file !== '' ? <img style={{ height: height, width: '100%' }} src={URL.createObjectURL(file)}/> : null} */}
                        {/* <Typography>{"Click to select a file"}</Typography> */}
                        <input
                           type="file"
                           id={`fileElem + ${id}`}
                           accept={
                              type === "csv"
                                 ? ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                 : type === "pdf"
                                 ? "application/pdf"
                                 : "image/*, video/*"
                           }
                           className={classes.hide}
                           value={""}
                           onChange={inputUpload}
                           multiple={multiple ? true : false}
                        />
                        {/* empty string value allows user to upload same file with same name twice if needed */}
                        <label
                           htmlFor={`fileElem + ${id}`}
                           className={classes.click}
                        ></label>
                        {/* {selectedMedia?.url && <img className={classes.img} src={selectedMedia?.url} alt={selectedMedia?.key} />} */}
                     </>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};
