import React from "react";
import { useState } from "react";
import {
   Grid,
   useTheme,
   InputAdornment,
   TextField,
   Button,
   Switch,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { mainTheme } from "../../styles/mainTheme";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as MailIcon } from "../../styles/assets/logos/mail-icon.svg";
import { ReactComponent as LockIcon } from "../../styles/assets/logos/locked-icon.svg";
import { useGradient } from "../../contexts/gradients/gradients.context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { login } from "../../contexts/api/api.functions";
import { LoginReq } from "../../contexts/api/api.types";
import { useNavigate } from "react-router-dom";
import {
   setStoredItem,
   clearStoredItem,
} from "../../contexts/common/localstorage";
import { StorageKeys } from "../../contexts/common/storeapp.types";
import { useUserDispatch, UserActions } from "../../contexts/user/user.context";

export function generateUUID() {
   let values = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
   ];
   let UUID = "";
   values.forEach((x) => {
      UUID +=
         values[Math.floor(Math.random() * (values.length - 1))].toString();
   });
   return UUID;
}

export const LoginForm: React.FC<{ setBackdrop: (value: boolean) => void }> = ({
   setBackdrop,
}) => {
   const theme = useTheme();
   const [isEmailValid, setIsEmailValid] = useState(false);
   const [isPasswordValid, setIsPasswordValid] = useState(false);
   const { classes } = useStyles();
   const { primaryButtonsGradient } = useGradient();
   const [passwordType, setPasswordType] = useState("password");
   const [emailLabelDisplay, setEmailLabelDisplay] = useState(false);
   const [passwordLabelDisplay, setPasswordLabelDisplay] = useState(false);
   const [remember, setRemember] = useState(false);
   const [submitError, setSubmitError] = useState<string | null>(null);
   const navigate = useNavigate();
   const dispatch = useUserDispatch();
   const schema = yup
      .object()
      .shape({
         email: yup
            .string()
            .email("Please provide a valid email.")
            .required("Email is required."),
         password: yup.string().required("Password is required"),
      })
      .required();

   const onSubmitLogin = async (data: any) => {
      setBackdrop(true);
      const body: LoginReq = {
         emailId: data.email,
         password: data.password,
         deviceId: generateUUID(),
         deviceToken: generateUUID(),
         deviceType: "web",
         platformId: 1,
         providerId: 1,
      };
      const res = await login(body);

      //console.log(res);
      if (res && res.data && res.data.status === true) {
         if (remember) {
            dispatch({ type: UserActions.login, payload: res.data.result });
         } else {
            dispatch({
               type: UserActions.loginSession,
               payload: res.data.result,
            });
         }

         // if (res.data.result.isRenter === "1") {
         //    navigate("/dashboard");
         // } else {
         if (res.data.result.isLender === 1) {
            navigate("/lender/dashboard");
         } else {
            navigate("/dashboard");
         }
         // }
      } else {
         if (res && res.data && res.data.status === false && res.data.message) {
            // setSubmitError(res.data.message)
            setSubmitError("Please enter a valid email and password.");
         } else {
            setSubmitError("Something went wrong.");
         }
         setBackdrop(false);
      }
   };

   const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting, touchedFields, isValid },
   } = useForm({
      resolver: yupResolver(schema),
   });
   const email = register("email");
   const password = register("password");
   const handleRemember = (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
   ) => {
      // console.log("checked?? ", checked);
      if (checked) {
         setStoredItem(StorageKeys.rememberMe, "true", false);
      } else {
         clearStoredItem(StorageKeys.rememberMe);
      }
      setRemember(checked);
   };

   function isLoginFieldsValid(field1: string, isEmail: boolean): void {
      if (isEmail) {
         if (field1.length >= 1) {
            setIsEmailValid(true);
         }

         if (field1.length < 1) {
            setIsEmailValid(false);
         }
      } else {
         if (field1.length >= 1) {
            setIsPasswordValid(true);
         }
         if (field1.length < 1) {
            setIsPasswordValid(false);
         }
      }
   }

   return (
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmitLogin)}>
         <Grid
            container
            direction={"column"}
            display="flex"
            justifyContent={"space-between"}
            sx={{ height: "100%", paddingBottom: "5px", gap: "10px" }}
         >
            <Grid item>
               <Grid container direction="column" display={"flex"} spacing={6}>
                  <Grid item>
                     <Grid
                        container
                        direction={"column"}
                        spacing={2}
                        display={"flex"}
                        justifyContent="center"
                        alignItems={"center"}
                     >
                        <Grid item>
                           <div className={classes.header}>
                              Log In To InstaShow
                           </div>
                        </Grid>
                        <Grid item>
                           <span className={classes.subHeader}>
                              Don't have an InstaShow account?
                           </span>
                           <span
                              className={classes.link}
                              onClick={() => {
                                 navigate("/register");
                              }}
                           >
                              Sign Up Here
                           </span>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "15px", paddingRight: "15px" }}>
                     <Grid
                        container
                        direction={"column"}
                        display={"flex"}
                        spacing={6}
                     >
                        <Grid item>
                           <TextField
                              type="email"
                              color="secondary"
                              label="EMAIL"
                              InputLabelProps={{
                                 classes: {
                                    root: classes.labelReguler,
                                    focused: classes.labelFocused,
                                 },
                                 style: {
                                    color: mainTheme.palette.primary.main,
                                    display: emailLabelDisplay
                                       ? "block"
                                       : "none",
                                 },
                                 shrink: true,
                              }}
                              placeholder="EMAIL"
                              {...email}
                              onFocus={() => {
                                 setEmailLabelDisplay(true);
                              }}
                              onBlur={() => {
                                 setEmailLabelDisplay(false);
                              }}
                              onChange={(e) => {
                                 email.onChange(e);
                                 isLoginFieldsValid(e.target.value, true);
                              }}
                              variant="standard"
                              inputProps={{
                                 style: {
                                    fontFamily: theme.fonts.secondary.bold,
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    display: "flex",
                                    paddingLeft: "5px",
                                 },
                              }}
                              fullWidth
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position="start"
                                       sx={{
                                          height: "15px",
                                          position: "relative",
                                          bottom: "2px",
                                          left: "5px",
                                       }}
                                    >
                                       <MailIcon />
                                    </InputAdornment>
                                 ),
                                 classes: {
                                    focused: classes.textFieldFocused,
                                    adornedStart: classes.adornmentStart,
                                    adornedEnd: classes.adornmentEnd,
                                 },
                                 disableUnderline: emailLabelDisplay
                                    ? true
                                    : false,
                              }}
                              sx={{
                                 fontFamily: theme.fonts.secondary.bold,
                                 fontWeight: "bold",
                                 "&& .MuiInput-underline:hover:before": {
                                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                                 },
                              }}
                           />
                        </Grid>
                        <Grid item>
                           <TextField
                              type={passwordType}
                              {...password}
                              label="PASSWORD"
                              placeholder="PASSWORD"
                              InputLabelProps={{
                                 classes: {
                                    root: classes.labelReguler,
                                    focused: classes.labelFocused,
                                 },
                                 style: {
                                    color: mainTheme.palette.primary.main,
                                    display: passwordLabelDisplay
                                       ? "block"
                                       : "none",
                                 },
                                 shrink: true,
                              }}
                              onChange={(e) => {
                                 password.onChange(e);
                                 isLoginFieldsValid(e.target.value, false);
                              }}
                              onFocus={() => {
                                 setPasswordLabelDisplay(true);
                              }}
                              onBlur={() => {
                                 setPasswordLabelDisplay(false);
                              }}
                              variant="standard"
                              inputProps={{
                                 style: {
                                    fontFamily: theme.fonts.secondary.bold,
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingLeft: "5px",
                                    display: "flex",
                                 },
                              }}
                              fullWidth
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position="start"
                                       sx={{
                                          height: "15px",
                                          position: "relative",
                                          bottom: "2.5px",
                                          left: "5px",
                                       }}
                                    >
                                       <LockIcon />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment
                                       position="end"
                                       sx={{
                                          cursor: "pointer",
                                          maxHeight: "15px",
                                          position: "relative",
                                          right: "5px",
                                       }}
                                       onClick={() => {
                                          if (passwordType === "text") {
                                             setPasswordType("password");
                                          } else {
                                             setPasswordType("text");
                                          }
                                       }}
                                    >
                                       {passwordType === "text" ? (
                                          <VisibilityOffIcon />
                                       ) : (
                                          <VisibilityIcon />
                                       )}
                                    </InputAdornment>
                                 ),
                                 disableUnderline: passwordLabelDisplay
                                    ? true
                                    : false,
                                 classes: {
                                    root: classes.textFieldReg,
                                    focused: classes.textFieldFocused,
                                    adornedStart: classes.adornmentStart,
                                    adornedEnd: classes.adornmentEnd,
                                    underline: classes.underline,
                                 },
                              }}
                              sx={{
                                 fontFamily: theme.fonts.secondary.bold,
                                 fontWeight: "bold",
                                 "&& .MuiInput-underline:hover:before": {
                                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                                 },
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid
                     item
                     justifyContent="center"
                     display={"flex"}
                     alignItems="center"
                  >
                     <Grid
                        container
                        direction={"column"}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                     >
                        <Grid item>
                           <Grid
                              container
                              direction={"column"}
                              display="flex"
                              alignItems="center"
                              spacing={3}
                           >
                              <Grid item>
                                 <div className={classes.rememberMe}>
                                    Remember Me
                                    <Switch
                                       value={remember}
                                       onChange={handleRemember}
                                       color="primary"
                                       size="small"
                                       sx={{ marginLeft: "3px" }}
                                    />
                                    {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                                 </div>
                              </Grid>
                              <Grid item>
                                 <Button
                                    className={classes.button}
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                       borderRadius: "25px",
                                       background:
                                          isEmailValid && isPasswordValid
                                             ? primaryButtonsGradient
                                             : "#A2A2A2",
                                       "&:hover": {
                                          borderRadius: "25px",
                                          background:
                                             isEmailValid && isPasswordValid
                                                ? primaryButtonsGradient
                                                : "#A2A2A2",
                                          marginTop: "-2px",
                                          marginBottom: "-1px",
                                          width: "175px",
                                          height: "48px",
                                       },
                                    }}
                                 >
                                    Log In
                                 </Button>
                              </Grid>
                              <Grid item>
                                 <span
                                    style={{
                                       color: theme.palette.primary.main,
                                       textDecoration: "underline",
                                       cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       navigate("/forgotpassword");
                                    }}
                                 >
                                    Forgot Password?
                                 </span>
                              </Grid>

                              <Grid item>
                                 {submitError ? (
                                    <div
                                       style={{
                                          color: "red",
                                          fontFamily: theme.fonts.primary.bold,
                                          fontWeight: "bold",
                                          textAlign: "center",
                                       }}
                                    >
                                       {submitError}
                                    </div>
                                 ) : null}
                              </Grid>
                              <Grid
                                 item
                                 display={"flex"}
                                 justifyContent="center"
                                 sx={{ paddingBottom: "8px" }}
                              >
                                 <span style={{ width: "155px" }}>
                                    Looking for the store?
                                 </span>
                                 <span
                                    className={classes.storeLink}
                                    onClick={() => {
                                       window.open(
                                          "https://instashowplus.com/hardware/",
                                          "_blank"
                                       );
                                    }}
                                 >
                                    Go to Shop
                                 </span>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </form>
   );
};

const useStyles = makeStyles({ name: "Login-Form" })((theme) => ({
   header: {
      fontFamily: mainTheme.fonts.secondary.bold,
      fontWeight: "bold",
      color: mainTheme.palette.common.medGray,
      fontSize: "26px",
   },
   subHeader: {
      fontSize: "16px",
      fontWeight: "normal",
      fontFamily: mainTheme.fonts.primary.reg,
   },
   link: {
      fontFamily: mainTheme.fonts.primary.reg,
      color: mainTheme.palette.primary.main,
      textDecoration: "underline",
      paddingLeft: "5px",
      cursor: "pointer",
   },
   button: {
      width: "160px",
      padding: "10px",
      borderRadius: "25px",
      fontFamily: mainTheme.fonts.primary.reg,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   storeLink: {
      color: mainTheme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
      paddingLeft: "5px",
   },
   textFieldFocused: {
      borderRadius: "25px",
      boxShadow: "4px 4px 7px 0px #A2A2A2",
      height: "45px",
   },
   adornmentStart: {
      paddingLeft: "5px",
   },
   adornmentEnd: {
      paddingRight: "5px",
   },
   labelReguler: {
      color: mainTheme.palette.common.medGray,
      paddingLeft: "30px",
   },
   labelFocused: {
      color: mainTheme.palette.common.medGray,
   },
   rememberMe: {
      fontSize: "14px",
      color: mainTheme.palette.common.medGray,
   },
   underline: {},
   textFieldReg: {
      "&& .MuiInput-underline:before": {
         borderBottom: "2px solid green",
      },
   },
}));
