/* eslint-disable @typescript-eslint/no-redeclare */
import React, { ReactElement } from "react";
import { makeStyles } from "tss-react/mui";
import GenericHeader from "../../components/generic-boxlty-header/generic-header";
import RegisterEmailComponent from "../../components/registration-components/register-email-component";
import { useEffect, useState } from "react";
import RegisterVerifyComponent from "../../components/registration-components/register-verify-component";
import RegisterPhoneComponent from "../../components/registration-components/register-phone-component";
import RegisterPasswordComponent from "../../components/registration-components/register-password-component";
import RegisterTermsComponent from "../../components/registration-components/register-terms-component";
import RegisterQuestionsComponent from "../../components/registration-components/register-questions-component";
import RegisterAssociatedComponent from "../../components/registration-components/register-associated-component";
import RegisterInvitedComponent from "../../components/registration-components/register-invited-component";
import RegisterRequestCompanyAssociateComponent from "../../components/registration-components/register-request-associate-component";
import { Navigate, useSearchParams } from "react-router-dom";
import RegisterCompanyComponent from "../../components/registration-components/register-company-component";
import RegisterRequestLicenseComponent from "../../components/registration-components/register-request-license-component";
import RegisterLicenseComponent from "../../components/registration-components/register-license-component";
import {
   login,
   register,
   additionalData,
   sendEmailVerificationCode,
   verifyEmailVerificationCode,
   additionalDataNewSaaS,
   addRealtorDetail,
} from "../../contexts/api/api.functions";
import {
   LoginReq,
   RegisterReq,
   AdditionalDataReq,
   AdditionalDataNewSaaSReq,
   AdditionalRealtorDataReq,
} from "../../contexts/api/api.types";
import { generateUUID } from "../../utils/genericUtils";
import RegisterWelcomeInstaShowComponent from "../../components/registration-components/register-welcomeinstashow-component";
import RegisterWhyUseBoxltyComponent from "../../components/registration-components/register-whyuseboxlty-component";
import RegisterChoosePlanComponent from "../../components/registration-components/register-chooseplan-component";
import RegisterAreYouRealtorComponent from "../../components/registration-components/register-areyourealtor-component";
import RegisterRealtorInformationComponent from "../../components/registration-components/register-realtorinformation-component";
import RegisterBusinessTierStepComponent from "../../components/registration-components/register-businesstierstep-component";
import { UserActions, useUserDispatch } from "../../contexts/user/user.context";
import { Backdrop, CircularProgress } from "@mui/material";
import StripeCheckoutSession from "../checkout/StripeCheckoutSession";

function RegisterSaaS() {
   const [snackBarOpen, setSnackBarOpen] = useState(false);
   const [responseMessage, setResponseMessage] = useState("");
   const [searchParams] = useSearchParams();
   const { classes } = useStyles();
   const [registrationStep, setRegistrationStep] = useState(1);

   const [email, setEmail] = useState("");
   const [code, setCode] = useState("");
   const [phone, setPhone] = useState("");
   const [password, setPassword] = useState("");
   const [terms, setTerms] = useState(false);
   const [questions, setQuestions] = useState([
      false,
      false,
      false,
      false,
      false,
   ]);
   const [associated, setAssociated] = useState(false);
   const [invited] = useState(false);
   const [requestAssociate, setRequestAssociate] = useState(false);
   const [companyInfo, setCompanyInfo] = useState(["", "", ""]);
   const [associatedCompany] = useState(false);
   const [requestLicense, setRequestLicense] = useState(false);
   const [license, setLicense] = useState("");
   const [isRealtor, setIsRealtor] = useState(false);
   const [realtorInfo, setRealtorInfo] = useState(["", ""]);
   const [chosenPlan, setChosenPlan] = useState<string | null>(
      searchParams !== null && searchParams.get("status") !== null
         ? searchParams.get("status")
         : ""
   );
   const [planNumber, setPlanNumber] = useState(1);
   const [chosenBillingSchedule, setChosenBillingSchedule] = useState(true); //true = month, false = year
   const [whyUse, setWhyUse] = useState("sell");
   const [loading, setLoading] = useState(false);
   const dispatch = useUserDispatch();

   useEffect(() => {
      setPlanNumberByPlanName(chosenPlan);
   }, [registrationStep, email, code]);

   //make register header a component
   //break each register step into a component
   //break register buttons into components
   interface ElementMap {
      [key: number]: ReactElement;
   }

   switch (searchParams.get("status")) {
      case "free":
         var steps: ElementMap = {
            1: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            2: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={3}
                  backStepOption1={1}
               />
            ),
            3: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={4}
                  backStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            4: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={5}
                  backStepOption1={3}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            5: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={6}
               />
            ),
            6: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={7}
                  nextStepOption2={0}
                  backStepOption1={5}
               />
            ),
            //TODO update this
            0: (
               <RegisterQuestionsComponent
                  onPreviousStep={backOption}
                  onSubmitQuestions={submitQuestionsFree}
                  defaultValue={questions}
                  nextStepOption1={8}
                  backStepOption1={6}
               />
            ),
            7: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={8}
                  backStepOption1={6}
               />
            ),
            8: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={9}
                  nextStepOption2={10}
                  backStepOption1={6}
               />
            ),
            9: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={14}
                  backStepOption1={8}
               />
            ),
            10: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={11}
                  nextStepOption2={12}
                  backStepOption1={8}
               />
            ),
            11: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={12}
                  backStepOption1={8}
               />
            ),
            12: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={13}
                  nextStepOption2={14}
                  backStepOption1={11}
               />
            ),
            13: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={14}
                  backStepOption1={12}
               />
            ),
            14: (
               <StripeCheckoutSession
                  status={whyUse}
                  plan={planNumber}
                  isMonthly={chosenBillingSchedule}
                  submitAdditionalData={submitAdditionalData}
                  upgrade={false}
               />
            ),
            // 14: <Navigate to="/welcomeinstashow?status=buy" />,
            16: (
               <RegisterWelcomeInstaShowComponent
                  nextStepOption1={17}
                  setRegistrationStep={setRegistrationStep}
                  whyUseBoxlty={whyUse}
                  submitAdditionalData={submitAdditionalData}
               />
            ),
            17: <Navigate to="/dashboard" />,
         };
         break;
      case "plus":
         var steps: ElementMap = {
            1: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            2: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={3}
                  backStepOption1={1}
               />
            ),
            3: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={4}
                  backStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            4: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={5}
                  backStepOption1={3}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            5: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={6}
               />
            ),
            6: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={7}
                  nextStepOption2={8}
                  backStepOption1={5}
               />
            ),
            7: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={9}
                  backStepOption1={6}
               />
            ),
            8: (
               <RegisterQuestionsComponent
                  onPreviousStep={backOption}
                  onSubmitQuestions={submitQuestions}
                  defaultValue={questions}
                  nextStepOption1={10}
                  backStepOption1={6}
               />
            ),
            //if a realtor TODO
            9: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={11}
                  nextStepOption2={13}
                  backStepOption1={8}
               />
            ),
            //if not a realtor
            10: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={12}
                  nextStepOption2={15}
                  backStepOption1={8}
               />
            ),
            //if a realtor
            11: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={21}
                  backStepOption1={9}
               />
            ),
            //if not a realtor
            12: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={21}
                  backStepOption1={10}
               />
            ),
            //if a realtor
            13: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={14}
                  nextStepOption2={17}
                  backStepOption1={9}
               />
            ),
            //if a realtor
            14: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={17}
                  backStepOption1={13}
               />
            ),
            //if not a realtor
            15: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={16}
                  nextStepOption2={19}
                  backStepOption1={10}
               />
            ),
            //if not a realtor
            16: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={19}
                  backStepOption1={15}
               />
            ),
            //if a realtor
            17: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={18}
                  nextStepOption2={21}
                  backStepOption1={14}
               />
            ),
            //if a realtor
            18: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={21}
                  backStepOption1={17}
               />
            ),
            //if not a realtor
            19: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={20}
                  nextStepOption2={21}
                  backStepOption1={16}
               />
            ),
            //if not a realtor
            20: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={21}
                  backStepOption1={19}
               />
            ),
            21: (
               <StripeCheckoutSession
                  status={whyUse}
                  plan={planNumber}
                  isMonthly={chosenBillingSchedule}
                  submitAdditionalData={submitAdditionalData}
                  upgrade={false}
               />
            ),
            22: (
               <RegisterWelcomeInstaShowComponent
                  nextStepOption1={23}
                  setRegistrationStep={setRegistrationStep}
                  whyUseBoxlty={whyUse}
                  submitAdditionalData={submitAdditionalData}
               />
            ),
            23: <Navigate to="/dashboard" />,
         };
         break;
      case "business":
         var steps: ElementMap = {
            1: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            2: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={3}
                  backStepOption1={1}
               />
            ),
            3: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={4}
                  backStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            4: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={5}
                  backStepOption1={3}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            5: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={6}
               />
            ),
            6: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={7}
                  nextStepOption2={8}
                  backStepOption1={5}
               />
            ),
            7: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={9}
                  backStepOption1={6}
               />
            ),
            8: (
               <RegisterQuestionsComponent
                  onPreviousStep={backOption}
                  onSubmitQuestions={submitQuestions}
                  defaultValue={questions}
                  nextStepOption1={10}
                  backStepOption1={6}
               />
            ),
            //if a realtor TODO
            9: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={11}
                  nextStepOption2={13}
                  backStepOption1={8}
               />
            ),
            //if not a realtor
            10: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={12}
                  nextStepOption2={15}
                  backStepOption1={8}
               />
            ),
            //if a realtor
            11: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={21}
                  backStepOption1={9}
               />
            ),
            //if not a realtor
            12: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={21}
                  backStepOption1={10}
               />
            ),
            //if a realtor
            13: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={14}
                  nextStepOption2={17}
                  backStepOption1={9}
               />
            ),
            //if a realtor
            14: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={17}
                  backStepOption1={13}
               />
            ),
            //if not a realtor
            15: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={16}
                  nextStepOption2={19}
                  backStepOption1={10}
               />
            ),
            //if not a realtor
            16: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={19}
                  backStepOption1={15}
               />
            ),
            //if a realtor
            17: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={18}
                  nextStepOption2={21}
                  backStepOption1={14}
               />
            ),
            //if a realtor
            18: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={21}
                  backStepOption1={17}
               />
            ),
            //if not a realtor
            19: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={20}
                  nextStepOption2={21}
                  backStepOption1={15}
               />
            ),
            //if not a realtor
            20: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={21}
                  backStepOption1={19}
               />
            ),
            21: (
               <RegisterBusinessTierStepComponent
                  nextStepOption1={22}
                  onSubmitChoosePlan={submitChoosePlan}
               />
            ),
            22: (
               <StripeCheckoutSession
                  status={whyUse}
                  plan={planNumber}
                  isMonthly={chosenBillingSchedule}
                  submitAdditionalData={submitAdditionalData}
                  upgrade={false}
               />
            ),
            23: (
               <RegisterWelcomeInstaShowComponent
                  nextStepOption1={24}
                  setRegistrationStep={setRegistrationStep}
                  whyUseBoxlty={whyUse}
                  submitAdditionalData={submitAdditionalData}
               />
            ),
            24: <Navigate to="/dashboard" />,
         };
         break;
      case "try":
         var steps: ElementMap = {
            1: (
               <RegisterWhyUseBoxltyComponent
                  nextStepOption1={2}
                  nextStepOption2={9}
                  onSubmitWhyUse={onSubmitWhyUse}
               />
            ),
            //Order for Rent/Buy Home
            2: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            3: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={4}
                  backStepOption1={2}
               />
            ),
            4: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={5}
                  backStepOption1={3}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            5: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={6}
                  backStepOption1={4}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            6: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={7}
               />
            ),
            7: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={8}
                  nextStepOption2={32}
                  backStepOption1={6}
               />
            ),
            8: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={32}
                  backStepOption1={7}
               />
            ),

            //Order for manage/sell properties
            9: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={10}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            10: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={11}
                  backStepOption1={9}
               />
            ),
            11: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={12}
                  backStepOption1={10}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            12: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={13}
                  backStepOption1={11}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            13: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={14}
               />
            ),
            14: (
               <RegisterChoosePlanComponent
                  onSubmitChoosePlan={submitChoosePlan}
                  nextStepOption1={15}
                  nextStepOption2={14}
                  backStepOption1={13}
               />
            ),
            //if free is chosen
            15: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={16}
                  nextStepOption2={24}
                  backStepOption1={14}
               />
            ),
            16: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={17}
                  backStepOption1={15}
               />
            ),
            17: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={18}
                  nextStepOption2={19}
                  backStepOption1={16}
               />
            ),
            18: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={32}
                  backStepOption1={17}
               />
            ),
            19: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={20}
                  nextStepOption2={21}
                  backStepOption1={17}
               />
            ),
            20: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={22}
                  backStepOption1={19}
               />
            ),
            //if you select not to associate to a company
            21: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={23}
                  nextStepOption2={32}
                  backStepOption1={19}
               />
            ),
            22: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={23}
                  nextStepOption2={32}
                  backStepOption1={20}
               />
            ),
            23: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={32}
                  backStepOption1={22}
               />
            ),
            //if free but not a realtor
            24: (
               <RegisterQuestionsComponent
                  onPreviousStep={backOption}
                  onSubmitQuestions={submitQuestions}
                  defaultValue={questions}
                  nextStepOption1={25}
                  backStepOption1={15}
               />
            ),
            25: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={26}
                  nextStepOption2={27}
                  backStepOption1={24}
               />
            ),
            26: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={32}
                  backStepOption1={24}
               />
            ),
            27: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={28}
                  nextStepOption2={29}
                  backStepOption1={25}
               />
            ),
            28: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={30}
                  backStepOption1={27}
               />
            ),
            29: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={31}
                  nextStepOption2={32}
                  backStepOption1={27}
               />
            ),
            30: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={31}
                  nextStepOption2={32}
                  backStepOption1={28}
               />
            ),
            31: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={32}
                  backStepOption1={29}
               />
            ),
            32: (
               <StripeCheckoutSession
                  status={whyUse}
                  plan={planNumber}
                  isMonthly={chosenBillingSchedule}
                  submitAdditionalData={submitAdditionalData}
                  upgrade={false}
               />
            ),
            33: (
               <RegisterWelcomeInstaShowComponent
                  nextStepOption1={33}
                  setRegistrationStep={setRegistrationStep}
                  whyUseBoxlty={whyUse}
                  submitAdditionalData={submitAdditionalData}
               />
            ),
            34: <Navigate to="/dashboard" />,
         };
         break;
      default:
         var steps: ElementMap = {
            1: (
               <RegisterWhyUseBoxltyComponent
                  nextStepOption1={2}
                  nextStepOption2={9}
                  onSubmitWhyUse={onSubmitWhyUse}
               />
            ),
            //Order for Rent/Buy Home
            2: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={2}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            3: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={3}
                  backStepOption1={2}
               />
            ),
            4: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={5}
                  backStepOption1={3}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            5: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={6}
                  backStepOption1={4}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            6: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={7}
               />
            ),
            7: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={8}
                  nextStepOption2={32}
                  backStepOption1={6}
               />
            ),
            8: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={32}
                  backStepOption1={7}
               />
            ),

            //Order for manage/sell properties
            9: (
               <RegisterEmailComponent
                  onSubmitEmail={submitEmail}
                  defaultValue={email}
                  nextStepOption1={10}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            10: (
               <RegisterPhoneComponent
                  onPreviousStep={backOption}
                  onSubmitPhone={submitPhone}
                  defaultValue={phone}
                  nextStepOption1={11}
                  backStepOption1={9}
               />
            ),
            11: (
               <RegisterPasswordComponent
                  onPreviousStep={backOption}
                  onSubmitPassword={submitPassword}
                  defaultValue={password}
                  nextStepOption1={12}
                  backStepOption1={10}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
                  responseMessage={responseMessage}
               />
            ),
            12: (
               <RegisterVerifyComponent
                  onPreviousStep={backOption}
                  onSubmitCode={submitCode}
                  email={email}
                  defaultValue={code}
                  nextStepOption1={13}
                  backStepOption1={11}
                  skippable={false}
                  snackBarOpen={snackBarOpen}
                  setSnackBarOpen={setSnackBarOpen}
               />
            ),
            13: (
               <RegisterTermsComponent
                  onPreviousStep={backOption}
                  onSubmitTerms={submitTerms}
                  defaultValue={terms}
                  nextStepOption1={14}
               />
            ),
            14: (
               <RegisterChoosePlanComponent
                  onSubmitChoosePlan={submitChoosePlan}
                  nextStepOption1={15}
                  nextStepOption2={14}
                  backStepOption1={13}
               />
            ),
            //if free is chosen
            15: (
               <RegisterAreYouRealtorComponent
                  onPreviousStep={backOption}
                  onSubmitIsRealtor={submitIsRealtor}
                  defaultValue={isRealtor}
                  nextStepOption1={16}
                  nextStepOption2={24}
                  backStepOption1={14}
               />
            ),
            16: (
               <RegisterRealtorInformationComponent
                  onPreviousStep={backOption}
                  onSubmitRealtorInfo={submitRealtorInfo}
                  defaultValue={realtorInfo}
                  nextStepOption1={17}
                  backStepOption1={15}
               />
            ),
            17: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={18}
                  nextStepOption2={19}
                  backStepOption1={16}
               />
            ),
            18: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={32}
                  backStepOption1={17}
               />
            ),
            19: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={20}
                  nextStepOption2={21}
                  backStepOption1={17}
               />
            ),
            20: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={22}
                  backStepOption1={19}
               />
            ),
            //if you select not to associate to a company
            21: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={23}
                  nextStepOption2={32}
                  backStepOption1={19}
               />
            ),
            22: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={23}
                  nextStepOption2={32}
                  backStepOption1={20}
               />
            ),
            23: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={32}
                  backStepOption1={22}
               />
            ),
            //if free but not a realtor
            24: (
               <RegisterQuestionsComponent
                  onPreviousStep={backOption}
                  onSubmitQuestions={submitQuestions}
                  defaultValue={questions}
                  nextStepOption1={25}
                  backStepOption1={15}
               />
            ),
            25: (
               <RegisterAssociatedComponent
                  onPreviousStep={backOption}
                  onSubmitAssociated={submitAssociated}
                  defaultValue={associated}
                  nextStepOption1={26}
                  nextStepOption2={27}
                  backStepOption1={24}
               />
            ),
            26: (
               <RegisterInvitedComponent
                  onPreviousStep={backOption}
                  onSubmitInvited={submitInvited}
                  defaultValue={invited}
                  nextStepOption1={32}
                  backStepOption1={24}
               />
            ),
            27: (
               <RegisterRequestCompanyAssociateComponent
                  onPreviousStep={backOption}
                  onSubmitRequestAssociate={submitRequestAssociate}
                  defaultValue={requestAssociate}
                  nextStepOption1={28}
                  nextStepOption2={29}
                  backStepOption1={25}
               />
            ),
            28: (
               <RegisterCompanyComponent
                  onPreviousStep={backOption}
                  onSubmitCompany={submitCompanyInfo}
                  defaultValue={companyInfo}
                  nextStepOption1={30}
                  backStepOption1={27}
               />
            ),
            29: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={31}
                  nextStepOption2={32}
                  backStepOption1={27}
               />
            ),
            30: (
               <RegisterRequestLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitRequestLicense={submitRequestLicense}
                  defaultValue={requestLicense}
                  nextStepOption1={31}
                  nextStepOption2={32}
                  backStepOption1={28}
               />
            ),
            31: (
               <RegisterLicenseComponent
                  onPreviousStep={backOption}
                  onSubmitLicense={submitLicense}
                  defaultValue={license}
                  nextStepOption1={32}
                  backStepOption1={29}
               />
            ),
            32: (
               <StripeCheckoutSession
                  status={whyUse}
                  plan={planNumber}
                  isMonthly={chosenBillingSchedule}
                  submitAdditionalData={submitAdditionalData}
                  upgrade={false}
               />
            ),
            33: (
               <RegisterWelcomeInstaShowComponent
                  nextStepOption1={33}
                  setRegistrationStep={setRegistrationStep}
                  whyUseBoxlty={whyUse}
                  submitAdditionalData={submitAdditionalData}
               />
            ),
            34: <Navigate to="/dashboard" />,
         };
         break;
   }

   async function nextStep() {
      let maxSteps: number = Number.parseInt(Object.keys(steps).pop() || "0");
      if (registrationStep + 1 < maxSteps) {
         setRegistrationStep(registrationStep + 1);
      }
   }

   async function submitRegistration(password: string, nextStepOption: number) {
      //await post register
      setLoading(true);
      let registerData: RegisterReq = {
         firstName: "",
         lastName: "",
         emailId: email,
         phone: Number.parseInt(phone),
         providerId: 1,
         providerUId: "",
         password: password,
         isManualEmailId: "",
      };
      register(registerData)
         .then(async (response: any) => {
            if (response.status === true) {
               nextStep();
               setTimeout(() => {
                  sendEmailVerificationCode({ input: email });
               }, 500);
               //setRegistrationStep(nextStepOption);
            } else {
               setSnackBarOpen(true);
               if (response.response.status === 409)
                  setResponseMessage(
                     "Account already created with these details"
                  );
               else {
                  setResponseMessage(response.message);
               }
            }
            setLoading(false);
         })
         .catch((error) => {
            // console.log(error);
            setResponseMessage("Something went wrong, please try again later");
            setSnackBarOpen(true);
            setLoading(false);
         });
      // setRegistrationStep(nextStepOption);
   }

   async function submitAdditionalData(isFree: boolean) {
      setLoading(true);
      //step 1 login using stored username and password
      let credentials: LoginReq = {
         emailId: email,
         password: password,
         deviceId: generateUUID(),
         deviceToken: generateUUID(),
         deviceType: "web",
         platformId: 1,
         providerId: 1,
      };
      await login(credentials).then(async (response) => {
         if (response && response.data && response.data.status === true) {
            dispatch({
               type: UserActions.loginSession,
               payload: response.data.result,
            });

            //now post additional data request
            let userDescription: number[] = [];
            for (let i = 0; i < questions.length; i++) {
               //add question number to user description if user selected that answer
               if (questions[i]) userDescription.push(i + 1);
            }

            let additionalDataObject: AdditionalDataReq = {
               userDesription: userDescription,
               isCompanyAssociate: requestAssociate ? 1 : 0,
               companyName: companyInfo[0],
               companyZip: "",
               companyContact: companyInfo[2],
               isLicenseVisible: requestLicense ? "1" : "0",
               companyLicense: companyInfo[1],
               //visitors: userRole = 1, and sellers/renters = 2
               //1:Visitor, 2:Owner, 3:Realtor Visitor, 4:Realtor Owner
               userRole: whyUse === "sell" ? 2 : 1,
            };
            await additionalData(additionalDataObject)
               .then(async (res) => {
                  if (res.status) {
                     await submitAdditionalDataSaaS(companyInfo)
                        .then(async (res2) => {
                           if (res.status) {
                              await submitAdditionalRealtorData(realtorInfo)
                              .then(
                                 (response) => {
                                    setLoading(false);
                                    if (isFree) {
                                       window.location.href = `/welcomeinstashow?status=${whyUse}`;
                                    }
                                 }
                              )
                           }
                        }
                     );
                  } else {
                     setSnackBarOpen(true);
                     if (res.response.status === 409)
                        setResponseMessage(
                           "Account already created with these details"
                        );
                     else {
                        setResponseMessage(res.response.message);
                     }
                  }
                  setLoading(false);
               })
               .catch((err) => {
                  setResponseMessage(
                     "Something went wrong, please try again later"
                  );
                  setSnackBarOpen(true);
                  setLoading(false);
               });
            // setTimeout(() => {
            //    // wait for 500 ms to make next request
            //    console.log("about to call API call for UAD");
            //    alert("In set timeout");
            //    additionalData(additionalDataObject)
            //       .then(async (res) => {
            //          if (res.status) {
            //             //Success, make the other additional data request
            //             await submitAdditionalDataSaaS(companyInfo);
            //             setLoading(false);
            //             if (isFree) {
            //                window.location.href = `/welcomeinstashow?status=${whyUse}`;
            //             }
            //          } else {
            //             setSnackBarOpen(true);
            //             if (res.response.status === 409)
            //                setResponseMessage(
            //                   "Account already created with these details"
            //                );
            //             else {
            //                setResponseMessage(res.response.message);
            //             }
            //          }
            //          setLoading(false);
            //       })
            //       .catch((err) => {
            //          setResponseMessage(
            //             "Something went wrong, please try again later"
            //          );
            //          setSnackBarOpen(true);
            //          setLoading(false);
            //       });
            // }, 500);
         } else {
            setResponseMessage("Something went wrong, please try again later");
            setSnackBarOpen(true);
         }
      });
   }

   async function submitAdditionalDataSaaS(companyInfo: string[]) {
      let additionalDataNewSaaSObject: AdditionalDataNewSaaSReq = {
         isCompanyAssociate: associatedCompany ? "1" : "0",
         isRenterManager: whyUse === "buy" ? "0" : "1",
         company: companyInfo[0],
         companyLicense: companyInfo[1],
         companyContact: companyInfo[2],
         isEntCodeAvl: "0",
         enterpriseCode: "1",
      };
      await additionalDataNewSaaS(additionalDataNewSaaSObject)
         .then((res) => {
            if (res.status) {
               // console.log(res);
               // console.log("saas response");
               //Success, make the other additional data request
               nextStep();
            } else {
               setSnackBarOpen(true);
               if (res.response.status === 409)
                  setResponseMessage(
                     "Account already created with these details"
                  );
               else {
                  setResponseMessage(res.message);
               }
            }
         })
         .catch((err) => {
            setResponseMessage("Something went wrong, please try again later");
            setSnackBarOpen(true);
         });
   }

   async function submitAdditionalRealtorData(realtorInfo: string[]) {
      let additionalRealtorDataObject: AdditionalRealtorDataReq = {
         licenseNo: realtorInfo[0],
         nrdsId: realtorInfo[1],
         userType: whyUse === "sell" ? 2 : 1
      };
      await addRealtorDetail(additionalRealtorDataObject)
         .then((res) => {
            if (res.status) {
               // console.log(res);
               // console.log("saas response");
               //Success, make the other additional data request
            } else {
               setSnackBarOpen(true);
               if (res.response.status === 409)
                  setResponseMessage(
                     "Account already created with these details"
                  );
               else {
                  setResponseMessage(res.message);
               }
            }
         })
         .catch((err) => {
            setResponseMessage("Something went wrong, please try again later");
            setSnackBarOpen(true);
         });
   }

   function backOption(stopOption: number) {
      setRegistrationStep(stopOption);
   }

   function getCurrentStep(): ReactElement {
      return steps[registrationStep];
   }

   // functions to get all pieces of data from each register step

   async function onSubmitWhyUse(nextStepOption: number, whyUseOption: string) {
      setWhyUse(whyUseOption);
      setRegistrationStep(nextStepOption);
   }

   async function submitEmail(email: string) {
      setEmail(email);
      nextStep();
   }

   async function submitCode(_code: string) {
      setLoading(true);
      setCode(_code);
      //verify code input value for next
      let verificationResponse = await verifyEmailVerificationCode({
         emailId: email,
         otp: _code,
      });

      if (
         verificationResponse.data != null &&
         verificationResponse.data.status
      ) {
         nextStep();
      } else {
         setResponseMessage("Could not verify code");
         setSnackBarOpen(true);
      }
      setLoading(false);
      //nextStep(); //TESTING TODO DELETE
   }

   function submitPhone(phone: string) {
      setPhone(phone);
      nextStep();
   }

   async function submitPassword(password: string, nextStepOption: number) {
      setPassword(password);

      //after we have password set we need to submit our registration call now
      await submitRegistration(password, nextStepOption);
   }

   function submitTerms(agreement: boolean) {
      setTerms(agreement);
      nextStep();
   }

   function submitQuestions(questions: []) {
      setQuestions(questions);
      nextStep();
   }

   function submitQuestionsFree(questions: [], nextOption1: number) {
      setQuestions(questions);
      setRegistrationStep(nextOption1);
   }

   function submitAssociated(
      associated: boolean,
      nextStep1: number,
      nextStep2: number
   ) {
      setAssociated(associated);

      if (associated) {
         //if associated go to you must be invited screen
         setRegistrationStep(nextStep1);
      } else {
         //if not associated go to would you like to associate screen
         setRegistrationStep(nextStep2);
      }
   }

   async function submitInvited(invited: boolean, nextOption: number) {
      setAssociated(invited);
      // await submitAdditionalData();
      setRegistrationStep(nextOption);
      //submitRegistration();
   }

   async function submitRequestAssociate(
      request: boolean,
      nextStepOption1: number,
      nextStepOption2: number
   ) {
      setRequestAssociate(request);
      if (request) {
         setRegistrationStep(nextStepOption1);
      } else {
         setRegistrationStep(nextStepOption2);
      }
   }

   function submitCompanyInfo(companyInfo: string[], nextStep: number) {
      setCompanyInfo(companyInfo);
      setRegistrationStep(nextStep);
   }

   async function submitRequestLicense(
      request: boolean,
      nextStepOption1: number,
      nextStepOption2: number
   ) {
      setRequestLicense(request);
      if (request) {
         //user wants to input license info
         setRegistrationStep(nextStepOption1);
      } else {
         //user does not want to add license submit additional data
         // await submitAdditionalData();
         setRegistrationStep(nextStepOption2);
      }
   }

   async function submitLicense(license: string, nextStepOption1: number) {
      setLicense(license);
      // await submitAdditionalData();
      setRegistrationStep(nextStepOption1);
   }

   async function submitIsRealtor(
      isRealtor: boolean,
      option1: number,
      option2: number
   ) {
      setIsRealtor(isRealtor);
      if (isRealtor) {
         setRegistrationStep(option1);
      } else {
         setRegistrationStep(option2);
      }
   }

   async function submitRealtorInfo(realtorInfo: string[], option1: number) {
      setRealtorInfo(realtorInfo);
      setRegistrationStep(option1);
   }

   async function submitChoosePlan(
      planChoice: string,
      schedulePlan: boolean,
      option1: number
   ) {
      setChosenPlan(planChoice); //free, plus, business
      setPlanNumberByPlanName(planChoice);
      setChosenBillingSchedule(schedulePlan);
      setRegistrationStep(option1);
   }

   function setPlanNumberByPlanName(planName: string | null) {
      // console.log(planName?.toLowerCase().replace(/\s/g, ""));
      // console.log(planName);
      if (!planName) {
         setPlanNumber(1);
      }

      switch (planName?.toLowerCase().replace(/\s/g, "")) {
         case "plus":
            setPlanNumber(2);
            break;
         case "businesstier1":
            setPlanNumber(3);
            break;
         case "business": //in case there is an issue with conversion of query string param value
            setPlanNumber(3);
            break;
         case "businesstier2":
            setPlanNumber(4);
            break;
         default:
            setPlanNumber(1); //plan name does not match, this will be used to deem that we do not want to checkout
            break;
      }
   }

   return (
      <section className={classes.RegisterPageBG}>
         <Backdrop
            open={loading}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
         >
            <CircularProgress color="primary" />
         </Backdrop>
         <GenericHeader />
         {getCurrentStep()}
      </section>
   );
}

const useStyles = makeStyles({ name: "RegisterPage" })(() => ({
   RegisterPageBG: {
      backgroundColor: "#F5F6F6",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
   },
}));

export default RegisterSaaS;
