import axios, { /* AxiosError, */ AxiosRequestConfig } from "axios";
// import { Dispatch } from "react";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
// import { IAction } from "../common/storebase.types";
import {
   APIRes,
   AdditionalDataNewSaaSReq,
   AdditionalDataReq,
   AdditionalRealtorDataReq,
   BaseRes,
   RegisterReq,
   readNotification,
   CancelTourRequest,
   UpdateLockReq,
   DeleteLockReq,
   MemberAssignedProperty,
   EmailPhoneNumberTeamRequest,
   AddLockRequest,
   TeamRequestWithPropertyId,
   SelfieRequestReq,
   FavPropertyReq,
   LenderInviteSaasFlow,
   LenderAssignLockReq,
   AdminInviteSaasFlow,
   UpdateUserEmailPhoneReq,
   CompanyDetailsReq,
   NonLenderAssignLockReq,
} from "./api.types";
import { LoginReq } from "./api.types";
import { VerifyOTP } from "./api.types";
import { getToursReq } from "./api.types";
import { ChangePasswordReq } from "./api.types";
import { UpdatePasswordReq } from "./api.types";
import {
   CreatePropertyRequest1General,
   CreatePropertyRequest2Photos,
   CreatePropertyRequest3Instructions,
   CreatePropertyRequest4Schedule,
   CreatePropertyRequest5AssignLock,
   CreatePropertyRequest6UserInvite,
   CreatePropertyRequest7Approval,
   SassFlowUserInvite,
   PreSignedUrlRequest,
} from "./api.types";
const createAxios = (baseUrl: string | void) => {
   const token = getStoredItem(StorageKeys.access_token);
   const clientSecret =
      process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "staging"
         ? process.env.REACT_APP_CLIENT_SECRET_PROD
         : process.env.REACT_APP_CLIENT_SECRET_DEV;
   const api = axios.create({
      // set env for environment
      baseURL: baseUrl
         ? baseUrl
         : process.env.REACT_APP_ENV === "local"
         ? `${process.env.REACT_APP_LOCAL}`
         : `${process.env.REACT_APP_CLOUD}`,
      headers:
         token === null
            ? {
                 /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                 is_website: "true",
                 is_website_user: 1,
                 client_secret: `${clientSecret}`,
                 "Access-Control-Allow-Origin": "*",
                 //sample access token -- this should be gotten from user context when created
              }
            : {
                 /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                 access_token: `${token}`,
                 is_website: "true",
                 is_website_user: 1,
                 client_secret: `${clientSecret}`,
                 "Access-Control-Allow-Origin": "*",
                 //sample access token -- this should be gotten from user context when created
              },
   });
   return api;
};
const createRunner = (baseUrl: string | void) => {
   return async function runAxios<T, E = {}>(
      config: Partial<AxiosRequestConfig>
   ) {
      let axios;
      if (baseUrl) axios = createAxios(baseUrl);
      else axios = createAxios();
      //console.log(axios);
      if (!axios) return undefined;
      try {
         const res: APIRes<T, E> = await axios({
            ...config,
            method: config.method ?? "GET",
         });
         //console.log(res.data?.Success);
         if (res.data?.Success || res.status) {
            return res.data;
         } else {
            return undefined;
         }
      } catch (e: any) {
         return e;
      }
   };
};
//// Return regular Axios response
const createRunnerRaw = (baseUrl: string | void) => {
   return async function runAxios<T, E = {}>(
      config: Partial<AxiosRequestConfig>
   ) {
      let axios;
      if (baseUrl) axios = createAxios(baseUrl);
      else axios = createAxios();
      try {
         const res: APIRes<T, E> = await axios({
            ...config,
            method: config.method ?? "GET",
         });
         return res;
      } catch (e: any) {
         //console.log(e);
         return e.response as BaseRes<T, E>;
      }
   };
};
const ENV = process.env.REACT_APP_ENV;
/// DEV Var Names
/// REACT_APP_USERMANAGEMENT_DEV
/// REACT_APP_PROPERTY_DEV
/// REACT_APP_ADMIN_DEV
/// REACT_APP_USER_DEV
/// REACT_APP_DESKTOP_DEV
/// REACT_APP_IDENTITY_DEV
/// REACT_APP_MESSAGING_DEV
/// PROD Var Names
/// REACT_APP_USERMANAGEMENT_PROD
/// REACT_APP_PROPERTY_PROD
/// REACT_APP_ADMIN_PROD
/// REACT_APP_USER_PROD
/// REACT_APP_DESKTOP_PROD
/// REACT_APP_IDENTITY_PROD
/// REACT_APP_MESSAGING_PROD
// unauth functions
//Login
export const login = async (data: LoginReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/login`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/login`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/login`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
//Register
export const register = async (data: RegisterReq) =>
   createRunner(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/register`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/register`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/register`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
export const additionalData = async (data: AdditionalDataReq) =>
   createRunner(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/add-additional-data-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/add-additional-data-new`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/add-additional-data-new`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
/* This includes updates that came after SaaS inclusion */
export const additionalDataNewSaaS = async (data: AdditionalDataNewSaaSReq) =>
   createRunner(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/add-additional-data`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/add-additional-data`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/add-additional-data`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
export const addRealtorDetail = async (data: AdditionalRealtorDataReq) =>
   createRunner(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/add-realtor-detail`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/add-realtor-detail`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/add-realtor-detail`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
export const sendEmailVerificationCode = async (data: { input: string }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/resend-otp`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/resend-otp`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/resend-otp`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
export const verifyEmailVerificationCode = async (data: VerifyOTP) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/verify-otp`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/verify-otp`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/verify-otp`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
//Forgot Password
export const forgotPasswordGetOTP = async (data: { input: string }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/resend-otp`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/resend-otp`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/resend-otp`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
export const verifyForgotPasswordOTP = async (data: VerifyOTP) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/verify-otp`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/verify-otp`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/verify-otp`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });
export const changePasswordAfterVerify = async (data: ChangePasswordReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_USERMANAGEMENT_PROD}/v2/user/reset-password`
         : ENV === "staging"
         ? `${process.env.REACT_APP_USERMANAGEMENT_STAGING}/v2/user/reset-password`
         : `${process.env.REACT_APP_USERMANAGEMENT_DEV}/v2/user/reset-password`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
//Dashboard
export const getUserProperties = async (data: ChangePasswordReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-list`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-list`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-list`
   )<{ data: any; Message: string }>({ data, method: "GET" });
///Listings
export const getUserPropertyList = async (
   itemsPerPage: number | undefined,
   filter: number | undefined
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/search-property-owner-new?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/search-property-owner-new?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/search-property-owner-new?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const getAdminPropertyList = async (
   itemsPerPage: number | undefined,
   filter: number | undefined
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/search-property-admin?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/search-property-admin?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/search-property-admin?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
// export const getUserListingsList = async () =>
//   createRunnerRaw(
//          ENV === "production"
//        ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-propertylist`
//        : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-propertylist`
//   )<{
//     data: any;
//     Message: string;
//   }>({
//     method: "GET",
//   });
export const getUserListingsListById = async (data: { pId: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-detail-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-detail-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-detail-new`
   )<{
      data: any;
      Message: string;
   }>({
      params: data,
      method: "GET",
   });
// Update Password from Settings
export const updatePassword = async (data: UpdatePasswordReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/change-password-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/change-password-new`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/change-password-new`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
export const getUserProfile = async (data: { userId: string | undefined }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/user-profile`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/user-profile`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/user-profile`
   )<{ data: any; Message: string; params: any }>({
      params: data,
      method: "GET",
   });
//GET user properties
// export const getUserProperties = async (data: { UserId: string | undefined }) =>
//   createRunnerRaw("http://localhost:3802/app/api")<{
//     data: any;
//     Message: string;
//   }>({
//     url: `v2/property/user-property?itemsPerPage=10&page=1&userId=${data.UserId}`,
//     data,
//     method: "GET",
//   });
export const getUpcomingTours = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${
              process.env.REACT_APP_PROPERTY_PROD
           }/v2/property/get-myTourList-owner?filter1=${1}&filter2=${1}&searchText=${""}&itemsPerPage=${20}&page=${1}`
         : ENV === "staging"
         ? `${
              process.env.REACT_APP_PROPERTY_STAGING
           }/v2/property/get-myTourList-owner?filter1=${1}&filter2=${1}&searchText=${""}&itemsPerPage=${20}&page=${1}`
         : `${
              process.env.REACT_APP_PROPERTY_DEV
           }/v2/property/get-myTourList-owner?filter1=${1}&filter2=${1}&searchText=${""}&itemsPerPage=${20}&page=${1}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });
export const getSpecificDateTours = async (chosenDate: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${
              process.env.REACT_APP_PROPERTY_PROD
           }/v2/property/get-myTourList-owner-specificdate?filter1=${1}&filter2=${1}&chosenDate=${chosenDate}&searchText=${""}&itemsPerPage=${20}&page=${1}`
         : ENV === "staging"
         ? `${
              process.env.REACT_APP_PROPERTY_STAGING
           }/v2/property/get-myTourList-owner-specificdate?filter1=${1}&filter2=${1}&chosenDate=${chosenDate}&searchText=${""}&itemsPerPage=${20}&page=${1}`
         : `${
              process.env.REACT_APP_PROPERTY_DEV
           }/v2/property/get-myTourList-owner-specificdate?filter1=${1}&filter2=${1}&chosenDate=${chosenDate}&searchText=${""}&itemsPerPage=${20}&page=${1}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });
export const getUserAlerts = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${
              process.env.REACT_APP_SETTING_PROD
           }/v2/setting/user-notification-count?notificationStatus=${0}&searchText=${""}&itemsPerPage=${100}&page=${1}`
         : ENV === "staging"
         ? `${
              process.env.REACT_APP_SETTING_STAGING
           }/v2/setting/user-notification-count?notificationStatus=${0}&searchText=${""}&itemsPerPage=${100}&page=${1}`
         : `${
              process.env.REACT_APP_SETTING_DEV
           }/v2/setting/user-notification-count?notificationStatus=${0}&searchText=${""}&itemsPerPage=${100}&page=${1}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });
export const updateUserAlertRead = async (data: readNotification) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/notification-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/notification-new`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/notification-new`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
//get property schedule
// export const getPropertySchedule = async (data: {}) =>
//   createRunnerRaw("http://localhost:3802/app/api")<{
//     data: any;
//     Message: string;
//   }>({ url: "v2/property/property-schedule", data, method: "GET" });
// //GET owner tour list
// //?filter1=1&filter2=1&itemsPerPage=10&page=1
// export const getOwnerTourList = async (data: { UserId: string | undefined }) =>
//   createRunnerRaw("http://localhost:3802/app/api")<{
//     data: any;
//     Message: string;
//   }>({
//     url: `v2/property/get-myTourList-owner?filter1=1&filter2=1&searchText=&itemsPerPage=1&page=1`,
//     data,
//     method: "GET",
//   });
// //Alerts
// export const getNotifications = async (data: { userId: any }) =>
//   createRunnerRaw("http://http://localhost:3803/app/api")<{
//     data: any;
//     Message: string;
//   }>({
//     url: `v2/setting/user-notification-list?notificationStatus=1`,
//     data,
//     method: "GET",
//   });
///Listings
export const getUserListingsList = async () => {
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-propertylist`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-propertylist`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-propertylist`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
};
//User
//Tours
export const getNonLenderEntepriseTours = async (data: getToursReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-teamTourList-non-lender-enterprise`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-teamTourList-non-lender-enterprise`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-teamTourList-non-lender-enterprise`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
      params: data,
   });

export const getUserToursOwner = async (data: getToursReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-myTourList-owner-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-myTourList-owner-desktop`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-myTourList-owner-desktop`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
      params: data,
   });

export const getTourDetails = async (tourId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/booking-detail?bookingId=${tourId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/booking-detail?bookingId=${tourId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/booking-detail?bookingId=${tourId}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const rescheduleVisit = async (tourId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/booking-detail?bookingId=${tourId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/booking-detail?bookingId=${tourId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/booking-detail?bookingId=${tourId}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "POST",
   });

export const cancelTour = async (data: CancelTourRequest) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-visit-status`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-visit-status`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-visit-status`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

//Listings
export const getUserListings = async (userId: number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-property?userId=${userId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-property?userId=${userId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-property?userId=${userId}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getListingFull = async (propertyId: number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-detail-new?pId=${propertyId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-detail-new?pId=${propertyId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-detail-new?pId=${propertyId}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getListing = async (propertyId: number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-property-overview-by-newFlow?pId=${propertyId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-property-overview-by-newFlow?pId=${propertyId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-property-overview-by-newFlow?pId=${propertyId}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });
export const getUserListings2 = async (userId: number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-property?userId=${userId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-property?userId=${userId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-property?userId=${userId}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });
// export const getUserPropertyList = async (userId: number) => {
//   createRunnerRaw(ENV === 'production' ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-property?userId=${userId}` : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-property?userId=${userId}`)<{
//     data: any;
//     Message: string;
//   }>({
//     method: "GET"
//   })
// }
// Create Listing
export const makePropertyDraft = async (data: { pId: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/draft-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/draft-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/draft-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });

export const activateProperty = async (data: { pId: number; status: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-visibility-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-visibility-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-visibility-new`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });

//// Admin

/// Step 1
export const createPropertyStepOneGeneral = async (
   data: CreatePropertyRequest1General
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-property-user-lat-long`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-property-user-lat-long`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-property-user-lat-long`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });
// Step 2
export const generatePreSignedUrl = async (data: PreSignedUrlRequest) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/generate-presigned-url`
         : ENV === "staging"
         ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/generate-presigned-url`
         : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/generate-presigned-url`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const removePropertyImages = async (data: {}) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/remove-property-images`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/remove-property-images`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/remove-property-images`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const createPropertyStepTwoPhotos = async (
   data: CreatePropertyRequest2Photos
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-new-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-new-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-new-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });
// Step 3
export const createPropertyStepThreeInstructions = async (
   data: CreatePropertyRequest3Instructions
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-new-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-new-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-new-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });
// Step 4
export const createPropertyStepFourSchedule = async (
   data: CreatePropertyRequest4Schedule
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-property-visit-schedule-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-property-visit-schedule-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-property-visit-schedule-new`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });
// Step 5
export const createPropertyStepFiveAssignLock = async (
   data: CreatePropertyRequest5AssignLock
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-new-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-new-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-new-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const getLockList = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-associated-locks-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-associated-locks-desktop`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-associated-locks-desktop`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
// Get Lock List
export const getUserLockList = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/get-associated-locks-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/get-associated-locks-desktop`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/get-associated-locks-desktop`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
// Get Lock List - Super Admin - Includes all locks of team
export const getAdminUserLockList = async (filter: Number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/nonlender-get-associated-locks?filter=${filter}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/nonlender-get-associated-locks?filter=${filter}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/nonlender-get-associated-locks?filter=${filter}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

//// Add a lock to user's lock list
export const addLockToUserLockList = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/add-associated-lock`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/add-associated-lock`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/add-associated-lock`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const getTeamMemberDetails = async (userId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-invited-user-detail-new-desktop?userId=${userId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-invited-user-detail-new-desktop?userId=${userId}`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-invited-user-detail-new-desktop?userId=${userId}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

//// Step 6
export const createPropertyStepSixUserInvite = async (
   data: CreatePropertyRequest6UserInvite
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-invites`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-invites`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-invites`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const userInviteSaasFlow = async (data: SassFlowUserInvite) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-invite-saas-flow`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-invite-saas-flow`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-invite-saas-flow`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const adminInviteSaasFlow = async (data: AdminInviteSaasFlow) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/admin-invite-saas-flow`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/admin-invite-saas-flow`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/admin-invite-saas-flow`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

//// get user Invites
//// Do not use this in create listing team tab,
export const getUserInviteList = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-invites`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-invites`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-invites`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const getPropertyInviteList = async (pId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-invites-for-property?pId=${pId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-invites-for-property?pId=${pId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-invites-for-property?pId=${pId}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
export const getPropertyMembers = async (pId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-members?pId=${pId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-members?pId=${pId}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-members?pId=${pId}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });
///// get User Team
export const getUserTeamMembers = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-team-members-by-saas-flow-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-team-members-by-saas-flow-desktop`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-team-members-by-saas-flow-desktop`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const removeInvite = async (data: { input: string }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/remove-user-invite-notifications`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/remove-user-invite-notifications`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/remove-user-invite-notifications`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });

export const removeAssignedTeamMember = async (data: {
   teamMemberId: number;
   pId: number;
}) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/remove-user-property-member`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/remove-user-property-member`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/remove-user-property-member`
   )<{
      data: any;
      Message: string;
   }>({
      method: "PUT",
   });

/// Step 7
export const createPropertyStepSevenApproval = async (
   data: CreatePropertyRequest7Approval
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-new-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-new-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-new-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

///Edit Listing

export const deleteProperty = async (data: { pId: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/delete-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/delete-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/delete-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });

export const archiveProperty = async (data: { pId: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/create-new-property`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/create-new-property`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/create-new-property`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

// export const createPropertyStepOne = async (data: CreatePropertyRequest1) => {
//   createRunnerRaw(`http://localhost:3802/app/api/v2/property/create-new-property`)<{
//     data: any;
//     Message: string;
//   }>({
//     data,
//     method: 'POST'
//   })
// }
export const uploadLogo = async (data: ChangePasswordReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/change-password`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/change-password`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/change-password`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
//Password Tab
export const changePassword = async (data: ChangePasswordReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/change-password`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/change-password`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/change-password`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });
//BillingCycle
export const getBillingCycle = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/billing-cycle`
         : ENV === "staging"
         ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/billing-cycle`
         : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/billing-cycle`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const addTeamMemberByEmailPhoneNumber = async (
   data: EmailPhoneNumberTeamRequest
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-invites-new-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-invites-new-desktop`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-invites-new-desktop`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });

export const addTeamMemberWithProperty = async (
   data: TeamRequestWithPropertyId
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-invites-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-invites-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-invites-new`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });

export const addAssociatedLock = async (data: AddLockRequest) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/add-associated-lock`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/add-associated-lock`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/add-associated-lock`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });

export const getTeamMembers = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-team-members-by-saas-flow-desktop`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-team-members-by-saas-flow-desktop`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-team-members-by-saas-flow-desktop`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getTeamMembersAdmin = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-team-members-by-saas-flow-desktop-admin`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-team-members-by-saas-flow-desktop-admin`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-team-members-by-saas-flow-desktop-admin`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getLockDetails = async (lockKey: String) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-lock-detail-new-desktop?lockDeviceId=${lockKey}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-lock-detail-new-desktop?lockDeviceId=${lockKey}`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-lock-detail-new-desktop?lockDeviceId=${lockKey}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const removeMemberFromTeam = async (data: {
   input: string | undefined;
}) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/remove-team-members-by-saas-flow`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/remove-team-members-by-saas-flow`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/remove-team-members-by-saas-flow`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const removeTeamMemberInvite = async (data: {
   input: string | undefined;
}) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/remove-user-invite-notifications`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/remove-user-invite-notifications`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/remove-user-invite-notifications`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const removeTeamMemberFromListing = async (
   data: MemberAssignedProperty
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/remove-user-property-member`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/remove-user-property-member`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/remove-user-property-member`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const createFuncs = () => {
   const runAxios = createRunnerRaw();
   return {
      getUserPropertyListV2: async (userId: number) =>
         await runAxios<{ data: any }>({
            url:
               ENV === "production"
                  ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/user-property?userId=${userId}`
                  : ENV === "staging"
                  ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/user-property?userId=${userId}`
                  : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/user-property?userId=${userId}`,
            method: "GET",
         }),
   };
};

export const updateLockName = async (data: UpdateLockReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/update-lock-detail-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/update-lock-detail-new`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/update-lock-detail-new`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const deleteLock = async (data: DeleteLockReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/delete-lock-detail-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/delete-lock-detail-new`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/delete-lock-detail-new`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const approveOrDenySelfieRequest = async (data: SelfieRequestReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/property-access-request-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/property-access-request-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/property-access-request-new`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const favoriteProperty = async (data: FavPropertyReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/favorite-property-new`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/favorite-property-new`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/favorite-property-new`
   )<{ data: any; Message: string }>({
      data,
      method: "POST",
   });

export const verifyEmail = async (email: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/check-email?email=${email}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/check-email?email=${email}`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/check-email?email=${email}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const removeFavoriteProperty = async (id: number | undefined) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/favorite-property?id=${id}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/favorite-property?id=${id}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/favorite-property?id=${id}`
   )<{ data: any; Message: string }>({
      method: "DELETE",
   });

export const getCouponDetails = async (coupon: String) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/coupon-code?coupon=${coupon}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/coupon-code?coupon=${coupon}`
         : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/coupon-code?coupon=${coupon}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const lenderInviteSaasFlow = async (data: LenderInviteSaasFlow) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/lender-invite-saas-flow`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/lender-invite-saas-flow`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/lender-invite-saas-flow`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const lenderAssignLock = async (data: LenderAssignLockReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/lender-assign-lock`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/lender-assign-lock`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/lender-assign-lock`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const nonLenderAssignLock = async (data: NonLenderAssignLockReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/nonlender-assign-lock`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/nonlender-assign-lock`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/nonlender-assign-lock`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const lenderGetLocks = async (filter: number) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/lender-get-associated-locks?filter=${filter}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/lender-get-associated-locks?filter=${filter}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/lender-get-associated-locks?filter=${filter}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const getLenderProperties = async (
   itemsPerPage: number | undefined,
   filter: number | undefined
) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/lender-property-list?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/lender-property-list?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/lender-property-list?lat=''&long=''&userType=1&filter=${filter}&itemsPerPage=${itemsPerPage}`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
   });

export const getLenderPartners = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-lender-partners`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-lender-partners`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-lender-partners`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getPartnerDetails = async (userId: string) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-partner-details?userId=${userId}`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-partner-details?userId=${userId}`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-partner-details?userId=${userId}`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const archivePartner = async (data: {
   userId: string | undefined;
   update: string | undefined;
}) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/archive-partner`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/archive-partner`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/archive-partner`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const updateCompanyDetails = async (data: CompanyDetailsReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/update-company-detail`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/update-company-detail`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/update-company-detail`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const getLenderDashboard = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/lender-dashboard`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/lender-dashboard`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/lender-dashboard`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getLenderLeads = async () =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/get-lender-leads`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/get-lender-leads`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/get-lender-leads`
   )<{ data: any; Message: string }>({
      method: "GET",
   });

export const getLenderTours = async (data: getToursReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/lender-tour-list`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/lender-tour-list`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/lender-tour-list`
   )<{
      data: any;
      Message: string;
   }>({
      method: "GET",
      params: data,
   });

export const updateUserEmailPhone = async (data: UpdateUserEmailPhoneReq) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/update-user-email-phone`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/update-user-email-phone`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/update-user-email-phone`
   )<{ data: any; Message: string }>({
      data,
      method: "PUT",
   });

export const uploadCompanyLogo = async (data: { companyLogo: string }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/upload-company-logo`
         : ENV === "staging"
         ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/upload-company-logo`
         : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/upload-company-logo`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "POST",
   });

export const addPhoneNumberToListing = async (data: { status: number }) =>
   createRunnerRaw(
      ENV === "production"
         ? `${process.env.REACT_APP_SETTING_PROD}/v2/setting/user-contact-detail`
         : ENV === "staging"
         ? `${process.env.REACT_APP_SETTING_STAGING}/v2/setting/user-contact-detail`
         : `${process.env.REACT_APP_SETTING_DEV}/v2/setting/user-contact-detail`
   )<{
      data: any;
      Message: string;
   }>({
      data,
      method: "PUT",
   });

//// CSV LISTING IMPORT
export const uploadListingCsvFile = async (data: any) => {
   const formData = new FormData();
   formData.append("file", data);
   return await createRunner(
      ENV === "production"
         ? `${process.env.REACT_APP_PROPERTY_PROD}/v2/property/upload-property-csv`
         : ENV === "staging"
         ? `${process.env.REACT_APP_PROPERTY_STAGING}/v2/property/upload-property-csv`
         : `${process.env.REACT_APP_PROPERTY_DEV}/v2/property/upload-property-csv`
   )<{
      data: any;
      Message: string;
   }>({
      data: formData,
      method: "POST",
      headers: {
         "Content-Type": "multipart/form-data",
      },
   });
};
