import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";

interface ActivateButtonProps {
   activateCallback: () => void;
   unverifiedCallback: () => void;
   listingType: string;
   canActivate: boolean;
   verifiedStatus: number;
}

export const ActivateButton: React.FC<ActivateButtonProps> = ({
   activateCallback,
   unverifiedCallback,
   listingType,
   canActivate,
   verifiedStatus,
}) => {
   const { classes } = useStyles();

   if (listingType === "active") {
      return null;
   }
   if (canActivate === false) {
      return (
         <Grid item>
            <Button
               variant="contained"
               classes={{ disabled: classes.activateDisabled }}
               disableElevation
               disabled={true}
               onClick={() => {}}
            >
               Activate
            </Button>
         </Grid>
      );
   }
   if (canActivate === true && verifiedStatus !== 1) {
      return (
         <Grid item>
            <Button
               variant="contained"
               classes={{ disabled: classes.activateDisabled }}
               disableElevation
               disabled={false}
               onClick={unverifiedCallback}
            >
               Activate
            </Button>
         </Grid>
      );
   }

   if (canActivate === true && verifiedStatus === 1) {
      return (
         <Grid item>
            <Button
               variant="contained"
               classes={{ disabled: classes.activateDisabled }}
               disableElevation
               disabled={false}
               onClick={activateCallback}
            >
               Activate
            </Button>
         </Grid>
      );
   }

   return null;
};

const useStyles = makeStyles({ name: "Activate-Button" })((theme) => ({
   activateDisabled: {
      backgroundColor: "#F5F6F6 !important",
      border: "1px solid rgba(0, 0, 0, 0.26) !important",
   },
}));
