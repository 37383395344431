import React, { ReactElement } from "react";

import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as DashboardIcon } from "../../../styles/assets/logos/dashboard-icon.svg";
import { ReactComponent as ListingsIcon } from "../../../styles/assets/logos/listing-icon.svg";
import { ReactComponent as ToursIcon } from "../../../styles/assets/logos/tours-icon.svg";
import { ReactComponent as TeamIcon } from "../../../styles/assets/logos/team-icon.svg";
import { ReactComponent as LocksIcon } from "../../../styles/assets/logos/locks-icon.svg";
import { ReactComponent as TokenIcon } from "../../../styles/assets/logos/token-icon.svg";
import { ReactComponent as ShopIcon } from "../../../styles/assets/logos/shop-icon.svg";
import { ReactComponent as LeadsIcon } from "../../../styles/assets/Icons/leads-icon-default.svg";
import { ReactComponent as PartnersIcon } from "../../../styles/assets/Icons/partners-icon-default.svg";
import { Dashboard } from "@mui/icons-material";

export interface INavElement {
   icon: any;
   activeIcon?: any;
   display: string;
   url: string;
   external?: boolean;
}

export const primaryNavList: Array<INavElement> = [
   { icon: <DashboardIcon />, display: "Dashboard", url: "/dashboard" },
   {
      icon: <ListingsIcon />,
      activeIcon: "",
      display: "Listings",
      url: "/listings",
   },
   { icon: <ToursIcon />, activeIcon: "", display: "Tours", url: "/tours" },
];

export const secondaryNavList: Array<INavElement> = [
   { icon: <TeamIcon />, activeIcon: "", display: "Team", url: "/team" },
   { icon: <LocksIcon />, activeIcon: "", display: "Locks", url: "/locks" },
   { icon: <TokenIcon />, activeIcon: "", display: "Tokens", url: "/tokens" },
];

export const shopNavItem: Array<INavElement> = [
   {
      icon: <ShopIcon />,
      activeIcon: "",
      display: "Shop",
      url: "https://instashowplus.com/hardware/",
      external: true,
   },
];

export const lenderPrimaryNavList: Array<INavElement> = [
   { icon: <DashboardIcon />, display: "Dashboard", url: "/dashboard" },
   {
      icon: <LeadsIcon />,
      activeIcon: "",
      display: "Leads",
      url: "/leads",
   },
   {
      icon: <PartnersIcon />,
      activeIcon: "",
      display: "Partners",
      url: "/partners",
   },
   { icon: <LocksIcon />, activeIcon: "", display: "Locks", url: "/locks" },
];

export const lenderSecondaryNavList: Array<INavElement> = [
   {
      icon: <ListingsIcon />,
      activeIcon: "",
      display: "Listings",
      url: "/listings",
   },
   { icon: <ToursIcon />, activeIcon: "", display: "Tours", url: "/tours" },
];
