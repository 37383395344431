import { SetStateAction } from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import ModalTransparentLayer from "../../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../../utils/genericUtils";
import { Button, Grid } from "@mui/material";
import {
   useCreateListingDispatch,
   useCreateListingState,
} from "../../../contexts/create-listing/create-listing.context";
import { useNavigate } from "react-router-dom";
import { ListingImportFileUpload } from "../../common/listing.import.file.upload.component";

interface position {
   top: string;
   left: string;
   right: string;
   bottom: string;
}

interface ActionButtonModalProps {
   id?: string;
   isVisible: boolean;
   setIsVisible: React.Dispatch<SetStateAction<boolean>>;
   openPosition: position;
   closedPosition: position;
   activeScreen: number;
   setActiveScreen: React.Dispatch<SetStateAction<number>>;
   isUploading: boolean;
   setIsUploading: React.Dispatch<SetStateAction<boolean>>;
   csvFile: File | null;
   setCsvFile: React.Dispatch<SetStateAction<File | null>>;
   uploadCSVFunc: () => void;
}

function ListingsCreateSlideOutModal(props: ActionButtonModalProps) {
   const [modalVisible, setModalVisible] = useState(props.isVisible);
   const [isInitialRender, setIsInitialRender] = useState(true);
   const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
   // const [isUploading, setIsUploading] = useState(false);
   const dispatch = useCreateListingDispatch();
   const { advancedNotice, propertyId, availabilityStatus } =
      useCreateListingState();
   const addLockTransparentModalId = "add-lock-transparent-layer";
   const TransparentLayerTransitionTime = 0.3;
   const ModalTransitionTime = 0.5;
   const instructionsDownloadUrl =
      "https://boxlty-media.s3.amazonaws.com/csv/Import_Properties_Instructions.csv";
   const templateDownloadUrl =
      "https://boxlty-media.s3.amazonaws.com/csv/Import_Properties_Spreadsheet.csv";
   const navigate = useNavigate();
   useEffect(() => {
      if (isInitialRender) {
         setIsInitialRender(false);
         return;
      }
      toggleModal(props.isVisible);
      toggleTransparentLayer(props.isVisible);
      // if (isInitialRender && props.isVisible === false) toggle(false);
   }, [props.isVisible]);

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggle(mode?: boolean | void) {
      if (mode != null) {
         toggleModal(mode);
         toggleTransparentLayer(mode);
         setModalVisible(mode);
      } else {
         toggleModal();
         toggleTransparentLayer();
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleTransparentLayer(mode: boolean | void) {
      //get transparent layer by id
      let transparentLayer: HTMLElement = document.querySelector(
         "#" + addLockTransparentModalId
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      }
      //manual mode not passed
      if (props.isVisible) {
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      } else {
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleModal(mode: boolean | void) {
      let modal: HTMLElement = document.querySelector(
         "#" + props.id
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
         return;
      }

      if (props.isVisible) {
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
      } else {
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
      }
   }

   const useStyles = makeStyles({ name: "AddLockModal" })((theme) => ({
      ModalComponentContainer: {
         overflow: "hidden",
      },
      AddLockModal: {
         transition: `right ${ModalTransitionTime}s linear`,
         position: "absolute",
         zIndex: 25,
         backgroundColor: "white",
         //backgroundColor : 'darkgray',
         borderRadius: "20px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
         bottom: props.closedPosition.bottom,
         right: props.closedPosition.right,
         padding: "20px",
         minHeight: "277px",
         minWidth: "350px",
         maxWidth: "400px",
      },
      modalHeader: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      modalSubHeader: {
         margin: 0,
         marginBottom: "10px",
         fontSize: "18px",
      },
      headerText: {
         margin: "0",
         textAlign: "left",
         width: "98%",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
         fontSize: "26px",
      },
      exitX: {
         marginLeft: "auto",
         fontSize: "25px",
         color: theme.palette.common.lightGray,
      },
      UnderlinedButtonContainer: {
         display: "flex",
         height: "55px",
         flexDirection: "column",
         marginRight: "10px",
         marginLeft: "10px",
      },
      ButtonSubLine: {
         width: "90px",
         height: "1px",
         maxHeight: "1px",
         minHeight: "1px",
         backgroundColor: theme.palette.common.darkGray,
      },
      AddItemRow: {
         display: "flex",
         alignItems: "center",
         marginBottom: "11px",
      },
      AddItemRowText: {
         width: "150px",
         marginRight: "50px",
         marginBottom: "2px",
         marginTop: "2px",
      },
      ModalFieldRow: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-start",
      },
      ModalInputBox: {
         display: "flex",
         flexDirection: "column",
         marginBottom: "19px",
         marginRight: "10px",
      },
      ModalInput: {
         fontFamily: theme.fonts.secondary.light,
         border: "none",
         outline: "none",
         width: "100%",
      },
      InputSubLine: {
         width: "100%",
         height: "1px",
         maxHeight: "1px",
         minHeight: "1px",
         backgroundColor: theme.palette.common.darkGray,
      },
      HalfWidthInput: {
         width: "50%",
      },
      FullWidthInput: {
         width: "100%",
      },
      SeventyFiveWidthInput: {
         width: "75%",
      },
      instaShow: {
         fontSize: "17px",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      TwentyFiveWidthInput: {
         width: "25%",
      },
      AddItemOuterContainer: {
         marginBottom: "45px",
      },
      AssignToListingText: {
         fontWeight: "100",
         margin: "0",
         marginBottom: "10px",
      },
      AssignToListingButtonOuterContainer: {
         width: "120px",
      },
      SaveSection: {
         marginTop: "70px",
         marginBottom: "10px",

         display: "flex",
         flexDirection: "row",
      },
      AssignToListingHeader: {
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      continueButton: {
         backgroundImage: `linear-gradient(270deg, #D63F94 0%, #B8519E 100%)`,
      },
      cancelButton: {
         backgroundColor: "white",
         color: theme.palette.common.medGray,
         border: `1px solid ${theme.palette.common.medGray}`,
         "&:hover": {
            backgroundColor: "white",
            color: theme.palette.common.medGray,
            border: `1px solid ${theme.palette.common.medGray}`,
         },
      },
      screen1Container: {
         display: "flex",
         flexDirection: "column",
         marginTop: "40px",
         justifyContent: "center",
         alignItems: "center",
      },
      screen2Container: {
         display: "flex",
         marginTop: "10px",
      },
      screen3Container: {
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         alignItems: "center",
         height: "277px",
      },
      screen3Heading: {
         marginBottom: "50px",
         fontFamily: theme.fonts.primary.bold,
         fontWeight: "bold",
         fontSize: "20px",
      },
      templateText: {
         fontFamily: theme.fonts.primary.med,
         textDecoration: "underline",
         cursor: "pointer",
      },
      instructionsText: {
         fontFamily: theme.fonts.primary.med,
         textDecoration: "underline",
         marginTop: "10px",
         cursor: "pointer",
      },
   }));

   let { classes } = useStyles();

   return (
      <>
         {/* Component Container */}
         <section className={classes.ModalComponentContainer}>
            {/* TransparentLayer */}
            <ModalTransparentLayer
               id={addLockTransparentModalId}
               transitionTime={TransparentLayerTransitionTime}
            />
            {/* ModdalConatiner */}
            <section id={props.id} className={classes.AddLockModal}>
               {/* header row */}
               <div className={classes.modalHeader}>
                  {props.activeScreen !== 2 ? (
                     <>
                        <h2 className={classes.headerText}>Add Listings</h2>
                        <span
                           className={classes.exitX}
                           style={{
                              cursor: props.isUploading ? "default" : "pointer",
                           }}
                           onClick={() => {
                              if (props.isUploading) {
                                 return;
                              }
                              if (props.csvFile !== null) {
                                 props.setActiveScreen(2);
                                 return;
                              }
                              props.setIsVisible(false);
                              props.setActiveScreen(0);
                           }}
                        >
                           &times;
                        </span>
                     </>
                  ) : null}
               </div>
               {props.activeScreen == 0 ? (
                  <div className={classes.screen1Container}>
                     <Button
                        variant="contained"
                        size="small"
                        sx={{
                           width: "150px",
                           borderRadius: "25px",
                           marginBottom: "10px",
                        }}
                        onClick={() => {
                           navigate("/listings/create");
                        }}
                     >
                        Add Manually
                     </Button>
                     <span style={{ marginBottom: "30px" }}>
                        Add details for a single property yourself.
                     </span>
                     <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                           props.setActiveScreen(1);
                        }}
                        sx={{
                           width: "150px",
                           borderRadius: "25px",
                           marginBottom: "10px",
                        }}
                     >
                        Import Listings
                     </Button>
                     <span>Quickly bring in listings from a CSV file.</span>
                  </div>
               ) : props.activeScreen == 2 ? (
                  <div className={classes.screen3Container}>
                     <div className={classes.screen3Heading}>
                        Are you sure you want to exit?
                     </div>
                     <Button
                        variant="contained"
                        size="small"
                        sx={{
                           width: "150px",
                           borderRadius: "25px",
                           marginBottom: "35px",
                        }}
                        onClick={() => {
                           props.setIsVisible(false);
                           props.setCsvFile(null);
                           props.setActiveScreen(0);
                        }}
                     >
                        Yes
                     </Button>
                     <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                           props.setActiveScreen(1);
                        }}
                        sx={{
                           width: "150px",
                           borderRadius: "25px",
                           marginBottom: "10px",
                        }}
                     >
                        No
                     </Button>
                  </div>
               ) : (
                  <div className={classes.screen2Container}>
                     <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                           <ListingImportFileUpload
                              headingText="Choose a CSV File"
                              loading={props.isUploading}
                              type="csv"
                              file={props.csvFile}
                              setFile={props.setCsvFile}
                              height="100px"
                           />
                        </Grid>
                        <Grid item>
                           <Grid container direction={"column"} spacing={3}>
                              <Grid item>
                                 <span style={{ fontStyle: "italic" }}>
                                    You can import up to 100 listings at a time.
                                 </span>
                              </Grid>
                              <Grid item>
                                 <Grid
                                    container
                                    direction="row"
                                    display="flex"
                                    justifyContent={"space-between"}
                                 >
                                    <Grid item>
                                       <Grid
                                          container
                                          direction="column"
                                          spacing={1.5}
                                       >
                                          <Grid item>
                                             <a
                                                href={templateDownloadUrl}
                                                style={{
                                                   color: "black",
                                                   fontWeight: 500,
                                                }}
                                             >
                                                Download template
                                             </a>
                                          </Grid>
                                          <Grid item>
                                             <a
                                                href={instructionsDownloadUrl}
                                                style={{
                                                   color: "black",
                                                   fontWeight: 500,
                                                }}
                                             >
                                                Download instructions
                                             </a>
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                    <Grid
                                       item
                                       sx={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                       }}
                                    >
                                       {props.csvFile ? (
                                          <div
                                             style={{ alignItems: "flex-end" }}
                                          >
                                             <Button
                                                variant="contained"
                                                disabled={
                                                   props.isUploading
                                                      ? true
                                                      : false
                                                }
                                                onClick={() => {
                                                   props.uploadCSVFunc();
                                                }}
                                             >
                                                Continue
                                             </Button>
                                          </div>
                                       ) : null}
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </div>
               )}
            </section>
         </section>
      </>
   );
}

export default ListingsCreateSlideOutModal;
