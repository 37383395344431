import React from "react";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as BoxltyFullLogo } from "../../styles/assets/logos/logo-on-black.svg";

function GenericHeader() {
   const { classes } = useStyles();

   return (
      <section className={classes.GenericHeaderBG}>
         <BoxltyFullLogo
            className={classes.headerSVG}
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
               window.location.href = "http://instashowplus.com/";
            }}
         />
      </section>
   );
}

const useStyles = makeStyles({ name: "GenericHeader" })(() => ({
   GenericHeaderBG: {
      backgroundColor: "#000000",
      width: "100%",
      height: "80px",
      paddingLeft: "25px",
      display: "flex",
      alignItems: "center",
      "@media (max-width: 550px)": {
         justifyContent: "center",
         alignItems: "center",
         paddingLeft: "0",
      },
   },
   headerSVG: {
      height: "30px",
      width: "auto",
      overflow: "hidden",
      //cursor: "pointer",
   },
}));

export default GenericHeader;
