import { AxiosResponse } from "axios";
import { updateUserEmailPhone } from "./api.functions";

type Success<T> = { Success: true } & T;

type Error<E> = {
   Success: boolean;
   Message: string;
   message: string;
   data: { token: string };
} & E;

export type BaseRes<T, E = {}> = Success<T> | Error<E>;

export type APIRes<T, E = {}> = AxiosResponse<BaseRes<T, E>>;

/////Place all Request Interfaces here

/// Example
// export interface LoginReq {
//     email: string;
//     password: string;
// }
/// Need Clarification from Boxlty endpoint looking for this
export interface LoginReq {
   deviceId: string;
   deviceToken: string;
   deviceType: string;
   emailId: string;
   password: string;
   platformId: number;
   providerId: number;
}

export interface RegisterReq {
   firstName: string;
   lastName: string;
   emailId: string;
   password: string;
   phone: number;
   providerId: number;
   providerUId: string;
   isManualEmailId: string;
}

export interface AdditionalDataReq {
   userDesription: number[];
   isCompanyAssociate: number;
   companyName: string;
   userRole: number;
   isLicenseVisible: string;
   companyLicense: string;
   companyZip: string;
   companyContact: string;
}

export interface AdditionalDataNewSaaSReq {
   isCompanyAssociate: string;
   isRenterManager: string;
   company: string;
   companyLicense: string;
   companyContact: string;
   isEntCodeAvl: string;
   enterpriseCode: string;
}

export interface AdditionalRealtorDataReq {
   licenseNo: string;
   nrdsId: string;
   userType: number;
}

export interface VerifyOTP {
   emailId: string;
   otp: string;
}

export interface ChangePasswordReq {
   input: string;
   otp: string;
   password: string;
}

export interface UserIdReq {
   userId: string;
}

//Used in account settings
export interface ChangePasswordLoggedReq {
   userId: string;
   oldPass: string;
   newPass: string;
}

export interface TeamMember {
   phone: number;
   emailId: string;
}

export interface CreatePropertyRequest1General {
   propertyHeadline: string;
   propertyCreatetypeId: string;
   nickName: string;
   address: string;
   city: string;
   state: string;
   zipCode: string;
   price: string;
   bedrooms: string;
   bathrooms: string;
   lotSize: string;
   propertyTypeId: string;
   sqFoot: string;
   parkingTypeId: string;
   parkingSpace: string;
   generalInformation: string;
   utilities: string;
   petPolicyId: string;
   petPolicyDescription: string;
   zlink: string;
   propertyId?: string;
   stepId: number;
}

export interface CreatePropertyRequest2Photos {
   stepId: number;
   propertyId: string;
   mainImg: any;
   images: string[];
}

export enum S3DirectoryName {
   DRIVERS_LICENSE = "DL",
   USER_IMAGE = "user",
   CHAT = "chatMedia",
   PROPERTY = "property",
   COMPANY_LOGO = "companyLogo",
}

export interface PreSignedUrlRequest {
   directory: S3DirectoryName;
   fileName: string;
}

export interface CreatePropertyRequest3Instructions {
   stepId: number;
   propertyId: string;
   accessInstruction: string;
   exitInstruction: string;
   postTourInstruction: string;
}

export interface UnavailableObject {
   date: string;
   slots: Array<number>;
}

export interface CreatePropertyRequest4Schedule {
   stepId: number;
   propertyId: string;
   noticeTime: number;
   isForceUpdate: number;
   unavaiableDates: Array<UnavailableObject>;
   isRecure: string;
   weekDays: Array<number>;
   timeInterval: Array<number>;
}

export interface CreatePropertyRequest5AssignLock {
   stepId: number;
   propertyId: string;
   lockTypeId: string | number;
   lockKey: string;
   doorImg: string | null;
   lockImg: string | null;
   igloolockTypeId: string | null;
   masterlockTypeId: string | null;
   accessChoice: number;
}

export interface SassFlowUserInvite {
   property: string;
   invites: Array<string>; ////// Array of user emails
   assign: Array<string>; ///// Still do not know what this is for, leave as empty array for now
}

export interface CreatePropertyRequest6UserInvite {
   propertyId: string;
   input: string;
}

export interface CreatePropertyRequest7Approval {
   propertyId: string;
   preRequest: string;
   paymentAccessOption: string;
   documentRequired: string;
   stepId: number;
}

export interface UpdatePasswordReq {
   currentPwd: string;
   newPwd: string;
}

export interface getToursReq {
   filter1: ToursFilter1;
   filter2: ToursFilter2;
   searchText: string;
   itemsPerPage: number;
   page: number;
}

export enum ToursFilter1 {
   BYDATE = 1,
   BYPROPERTY = 2,
}

export enum ToursFilter2 {
   UPCOMING = 1,
   TODAY = 2,
   TOMMORROW = 3,
   PAST30DAYS = 4,
   PAST60DAYS = 5,
}

export interface AlertResponse {
   actionPerformed: string;
   bookingDate: string;
   bookingFrom: string;
   bookingTo: string;
   isVisitorBlocked: number;
   notificationBookingId: number;
   notificationCreatedAt: string;
   notificationDesc: string;
   notificationId: number;
   notificationPropertyAddress: string;
   notificationPropertyId: number;
   notificationPropertyIglooLockTypeId: string;
   notificationPropertyLockTypeId: string;
   notificationPropertyNickname: string;
   notificationSenderFirstName: string;
   notificationSenderId: number;
   notificationSenderLastName: string;
   notificationSenderName: string;
   notificationStatus: string;
   notificationTitle: string;
   notificationTypeId: number;
}

export interface ToursResponse {
   bookingDate: string;
   bookingId: number;
   bookingStatusDesc: string;
   bookingStatusId: string;
   bookingStatusIdNew: string;
   bookingd: string;
   endTime: string;
   instaExtendedSlotTo: string;
   isInstashowVisit: string;
   isTourRescheduled: number;
   paymentAccessOptionCode: string;
   preAppDocUrl: string;
   propertyAddress: string;
   propertyCity: string;
   propertyFeaturePhoto: string;
   propertyId: string;
   propertyName: string;
   propertyState: string;
   propertyZipCode: string;
   startTime: string;
   timeSlotId: number;
   updatedAt: string;
   visitPayMode: string;
   visitorEmail: string;
   visitorFirtName: string;
   visitorId: number;
   visitorLastName: string;
   visitorMobile: string;
   visitorProfile: string;
}

export interface readNotification {
   id: number;
   actionType: number;
}

export interface CancelTourRequest {}

export interface SassFlowUserInvite {
   property: string;
   invites: Array<string>; ////// Array of user emails
   assign: Array<string>; ///// Still do not know what this is for, leave as empty array for now
}

export enum ExpectedRole {
   MANAGER,
   PRIMARY_MANAGER,
}

export interface AdminInviteSaasFlow {
   propertyId: number[];
   companyName: string | null;
   contactInput: string;
   expectedRole: ExpectedRole;
   pmUserId: number | null;
}

export interface UpdateLockReq {
   iglooLockTypeId: Number | undefined;
   lockActivationCode: string | undefined;
   lockDeviceId: string | undefined;
   lockManufacturer: Number | undefined;
   lockNickname: string | undefined;
   masterLockTypeId: Number | undefined;
}

export interface SelfieRequestReq {
   bookingId: number;
   propertyAccessId: number;
   status: boolean;
}

export interface DeleteLockReq {
   lockDeviceId: string;
}
export interface MemberAssignedProperty {
   pId: string;
   teamMemberId: string;
}
export interface EmailPhoneNumberTeamRequest {
   input: string;
}

export interface TeamRequestWithPropertyId {
   input: string;
   propertyId: string;
}

export interface AddLockRequest {
   propertyId?: string | null;
   nickName: string;
   activationCode: string;
   companyCode: string;
   lockTypeId: string;
   lockKey: string;
   iglooLockType: string;
   masterLockType: string;
}

export interface AddLockAdminRequest {
   pmUserId?: string | null;
   nickName: string;
   activationCode: string;
   companyCode: string;
   lockTypeId: string;
   lockKey: string;
   iglooLockType: string;
   masterLockType: string;
}

export interface addLockReq {}

export interface FavPropertyReq {
   pId: string;
   isVisitStatus: boolean;
   isFavorite: number;
}

export interface LenderInviteSaasFlow {
   lockKey: string[];
   companyName: string | null;
   contactInput: string;
   expectedRole: ExpectedRole;
   pmUserId: number | null;
}

export interface LenderAssignLockReq {
   lockKey: string;
   assignTo: number;
}

export interface NonLenderAssignLockReq {
   lockKey: string;
   assignTo: number;
}

export interface CompanyDetailsReq {
   companyName: string;
   companyLicense: string;
   companyContact: number;
}
export interface UpdateUserEmailPhoneReq {
   email: string;
   phone: string;
}
